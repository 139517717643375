<template>
  <div>
    <div
      ref="container"
      :class="errorElementClasses"
      class="error-message"
      tabindex="-1"
    >
      <div ref="errorMessageElement" :class="slotClasses">
        <div v-if="html" v-html="sanitizedHTML"></div>
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
import VueSecureHTML from 'vue-html-secure'

export default {
  name: 'ErrorMessage',
  props: {
    html: {
      type: String,
      default: undefined
    },
    isAlert: {
      type: Boolean,
      default: true
    },
    isAlertError: {
      type: Boolean,
      default: true
    },
    isAlertValidation: {
      type: Boolean,
      default: false
    },
    isAlertWithButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    errorElementClasses() {
      return {
        alert: this.isAlert,
        'alert--error': this.isAlertError,
        'alert--validation': this.isAlertValidation
      }
    },
    slotClasses() {
      return {
        'alert--with-button': this.isAlertWithButton
      }
    },
    sanitizedHTML() {
      return VueSecureHTML.safeHTML(this.html)
    }
  },
  methods: {
    focus() {
      this.$refs.container.focus()
    }
  }
}
</script>
<style lang="scss" scoped>
.error-message {
  &:focus {
    border: 1px solid var(--error-color);
    animation: wiggle 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
}

.alert--validation {
  border: 1px solid $c_error-red;
  border-radius: 5px;
  margin-right: 6px;

  [data-theme='dark'] & {
    border-color: $c_white;
  }
}

:deep(.error-report--icon) {
  position: relative;
  top: -1px;
  left: -5px;
}

:deep(.button-component.button-component__secondary) {
  @include breakpoint($up-to-tablet) {
    margin-bottom: 1rem;
  }
}
</style>
